
/**
 * @name: 销售管理-订单物流查询
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-订单物流查询
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  orderDeliveryDetailApi
} from '@/apis/sale/order'
import {
  logisticPageApi,
  logisticExportApi,
  logisticNoticeApi
} from '@/apis/sale/logistic'
import {
  ILogistic,
  ILogisticParams
} from '@/apis/sale/logistic/types'
import config from '@/config'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile, getlogisticStatus } from "@/utils/common";

@Component({})
export default class saleOrder extends Vue {
  config = config
  getlogisticStatus = getlogisticStatus;
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ILogistic[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ILogisticParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ILogistic> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    column: [
      {
        label: '物流单号',
        prop: 'logisticCode',
        search: true,
        align: 'center',
        slot: true,
        width: 180
      },
      {
        label: '快递公司',
        prop: 'logisticName',
        search: true,
      },
      {
        label: '关联订单ID',
        prop: 'orderId',
        search: true,
        align: 'center'
      },
      {
        label: '会员',
        prop: 'member',
        search: true,
        hide: true,
        placeholder: '昵称/手机号'
      },
      {
        label: '昵称',
        prop: 'nickName',
        align: 'center'
      },
      {
        label: '手机号',
        prop: 'phone',
        align: 'center',
        slot: true
      },
      {
        label: '收货地址',
        prop: 'detailAddress',
        align: 'center',
        slot: true
      },
      {
        label: '备注',
        prop: 'remarks',
        align: 'center'
      },
      {
        label: '发货时间',
        prop: 'addTime',
        align: 'center',
        type: 'daterange',
        search: true
      }
    ]
  }

  deliveryDetailDialog = false

  // 物流信息
  logisticsInfo: any = {}

  selectDeliveryRow: any = {}

  selectId = ''

  remarkDialog = false

  remarkInput = ''

  getList () {
    this.tableLoading = true

    const query: ILogisticParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    logisticPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  remarkEnter (done: Function, loading: Function) {
    logisticNoticeApi({
      id: this.selectId,
      remarks: this.remarkInput
    }).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  remarkClose () {
    this.remarkInput = ''
    this.selectId = ''
  }

  openRemarkDialog (row: ILogistic) {
    this.selectId = row.id
    this.remarkInput = row.remarks
    this.remarkDialog = true
  }

  openDeliveryDetail (row: ILogistic) {
    this.selectDeliveryRow = row
    this.logisticsInfo.logisticName = row.logisticName
    this.logisticsInfo.logisticCode = row.logisticCode
    orderDeliveryDetailApi(row.id).then(e => {
      this.logisticsInfo.list = e || []
      this.deliveryDetailDialog = true
    })
  }

  exportExcel () {

    const query: ILogisticParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addStartTime = query.addTime[0]
      query.addEndTime = query.addTime[1]
    } else {
      query.addStartTime = ''
      query.addEndTime = ''
    }
    delete query.addTime

    logisticExportApi(query).then(e => {
      exportFile(e, '订单物流.xlsx')
    })
  }

  created () {
    this.getList()
  }
}
