/**
 * @name: 销售管理-订单物流查询接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 销售管理-订单物流查询接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { ILogistic, ILogisticParams } from "./types";


export const logisticPageApi = (params: ILogisticParams) => get<IPageRes<ILogistic[]>>('/admin/order/deliver/getPage', params)

export const logisticExportApi = (params: ILogisticParams) => get('/admin/order/deliver/export', params, 'blob')

export const logisticNoticeApi = (data: {id: string, remarks: string}) => postJ('/admin/order/deliver/editNotes', data)
